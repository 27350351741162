import React from "react";
import { Link } from "gatsby";

export const menues = [
  {
    name: "会社概要",
    href: "/aboutus/about",
  },
  {
    name: "代表取締役社長：水野真澄プロフィール",
    href: "/aboutus/mizuno",
  },
  {
    name: "水野真澄講演会履歴",
    href: "/aboutus/rireki",
  },
  {
    name: "新聞・雑誌・メディアでの紹介",
    href: "/aboutus/media",
  },
];

const AboutMenu = () => {
  return (
    <div className="bg-gray-100 text-gray-600 font-sans text-sm tracking-wide">
      <div className="container mx-auto max-w-4xl py-3 flex md:justify-around flex-col md:flex-row text-center gap-2 md:gap-0">
        {menues.map((menu, index) => (
          <Link
            key={index}
            to={`${menu.href}`}
            activeClassName="text-cyan-600"
            className="hover:text-blue-500"
          >
            {menu.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AboutMenu;
