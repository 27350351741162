import React from "react";
import Layout from "../../components/layout";
import AboutMenu from "../../components/about/menu";
import H1 from "../../components/h1";
import Seo from "../../components/seo";

const MediaPage = () => {
  return (
    <Layout>
      <Seo title="新聞・雑誌・メディアでの紹介" />
      <main>
        <H1>会社概要</H1>
        <AboutMenu />
        <div className="bg-cyan-50 space-y-3 md:space-y-5 py-3 md:py-5">
          <div className="container mx-auto max-w-4xl py-5 md:py-10 px-3 md:px-5 bg-white">
            <div className="mx-auto max-w-2xl text-sm md:text-base divide-y border-gray-200">
              {articles.map((a, index) => (
                <div key={index} className="flex py-5 text-sm md:text-base">
                  <div className="w-1/4 md:w-1/5 font-number font-light mr-1 md:mr-2">
                    {a.date}
                  </div>
                  <div className="w-3/4 md:w-4/5">
                    <div className="font-sans mb-3">
                      {a.url ? (
                        <a
                          href={a.url}
                          className="text-cyan-500 hover:text-cyan-400"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {a.media}
                        </a>
                      ) : (
                        a.media
                      )}
                    </div>
                    <div className="text-xs md:text-sm font-extralight tracking-wide">
                      {a.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default MediaPage;

const articles = [
  {
    date: "2024-02-22",
    media: "The Daily NNA",
    url: "https://www.nna.jp/news/2627380",
    description:
      "当社（水野コンサルタンシーホールディングス）と八十二銀行との、香港・華南地域における包括的な連携協定の締結、及び、香港・華南デスクの共同設置が報道されました。",
  },
  {
    date: "2024-02-20",
    media: "日本経済新聞",
    url: "https://www.nikkei.com/article/DGXZQOCC208P90Q4A220C2000000",
    description:
      "当社（水野コンサルタンシーホールディングス）と八十二銀行との、香港・華南地域における包括的な連携協定の締結、及び、香港・華南デスクの共同設置が報道されました。",
  },
  {
    date: "2022-10-11",
    media: "The Daily NNA",
    url: "https://www.nna.jp/news/show/2405935",
    description:
      "2022年10月11日のThe Daily NNA・中国版内「あなたの中国」コーナーに、弊社代表・水野の半生が掲載されました。",
  },
  {
    date: "2021-06-29",
    media: "日本経済新聞",
    url: "",
    description:
      "2021年6月29日の日本経済新聞朝刊・深圳賃金支払い条例改定に関する記事に、弊社代表水野のコメントが掲載されました。",
  },
  {
    date: "2018-12-26",
    media: "東洋経済オンライン",
    url: "",
    description:
      "2018年12月26日の東洋経済オンライン『怒濤の勢いで浸透､中国｢スマホ決済｣の実態』で、弊社代表水野のコメントが掲載されました。",
  },
  {
    date: "2018-09-25",
    media: "日本経済新聞",
    url: "",
    description:
      "2018年9月25日の日本経済新聞朝刊・私見卓見のコーナーで、 弊社代表水野が中国の越境Eコマースに付いて解説しました。",
  },
  {
    date: "2018-09-10",
    media: "Daily NNA",
    url: "",
    description:
      "香港での開業10周年記念パーティーが、2018年9月10日付けDaily NNAで 掲載されました。尚、水野コンサルタンシーグループは、2018年8月31日に上海（揚子精品酒店）、 9月7日に香港（日本人倶楽部）で、開業10周年記念パーティーを開催致しました。",
  },
  {
    date: "2017-10-02",
    media: "NNAジョブワイア",
    url: "",
    description: "NNAジョブワイアに弊社代表水野の半生記が掲載されました。",
  },
  {
    date: "2016-03-04",
    media: "日本経済新聞",
    url: "",
    description:
      "日本経済新聞朝刊で、水野真澄のコラム（日本経済新聞電子版）が紹介されました。",
  },
  {
    date: "2015-11-02",
    media: "ヤフーニュース・BCNBizline",
    url: "",
    description:
      "水野コンサルタンシーホールディングスとPCA、上海安為信息技術3社共催セミナー「中国子会社の見える化～財務・税務管理と報告～」（10月27日、上海市にて開催）がヤフーニュース・BCNBizlineで報道されました。",
  },
  {
    date: "2015-09-17",
    media: "北日本新聞朝刊、富山新聞朝刊",
    url: "",
    description:
      "北日本新聞朝刊、富山新聞朝刊で、9月16日に開催された、水野真澄の講演会（北陸銀行主催）が報道されました。",
  },
  {
    date: "2015-09-02",
    media: "時事通信（中国本土・香港・タイ・マレーシア・インドネシア版）",
    url: "",
    description:
      "時事通信で、水野コンサルタンシーベトナム法人設立が報道されました。",
  },
  {
    date: "2015-09-03",
    media: "時事通信（ベトナム版）",
    url: "",
    description:
      "時事通信で、水野コンサルタンシーベトナム法人設立が報道されました。",
  },
  {
    date: "2015-08-13",
    media: "NNA（ベトナム版）",
    url: "",
    description: "水野コンサルタンシーベトナム法人設立が報道されました。",
  },
  {
    date: "2015-08-03",
    media: "日経産業新聞",
    url: "",
    description:
      "中国の自由貿易試験区に関する水野真澄の取材記事が掲載されました。",
  },
  {
    date: "2015-01-01",
    media: "コンシェルジュ香港（2015年1・2月号）",
    url: "",
    description:
      "コンシェルジュ香港（2015年1・2月号）Hong Kong Power Peopleで 水野真澄が紹介されました",
  },
  {
    date: "2014-08-27",
    media: "日経産業新聞、時事通信、NNA",
    url: "",
    description:
      "弊社代表水野真澄、シニアアドバイザー松本健三がコーディネーターを務める日本貿易振興機構（ジェトロ）「中小企業海外展開現地支援プラットフォーム（上海・江蘇省・浙江省）」に関する記事が日経産業新聞、時事通信、NNAなどのメディアに掲載されました。",
  },
  {
    date: "2014-08-21",
    media: "神奈川県記者発表資料",
    url: "http://www.pref.kanagawa.jp/prs/p831451.html",
    description:
      "神奈川県による水野真澄講演中国ビジネスセミナーに関するプレス・リリース",
  },
  {
    date: "2013-03-02",
    media: "BSジャパン「日経スペシャル　アジアの風」",
    url: "http://www.bs-j.co.jp/asianwind/bn48.html",
    description:
      "BSジャパン「日経スペシャル　アジアの風」に弊社代表水野真澄が出演いたしました。",
  },
  {
    date: "2013-02-02",
    media: "BSジャパン「日経スペシャル　アジアの風」",
    url: "http://www.bs-j.co.jp/asianwind/bn44.html",
    description:
      "BSジャパン「日経スペシャル　アジアの風」に弊社代表水野真澄が出演いたしました。",
  },
  {
    date: "2013-01-12",
    media: "BSジャパン「日経スペシャル　アジアの風」",
    url: "http://www.bs-j.co.jp/asianwind/bn41.html",
    description:
      "BSジャパン「日経スペシャル　アジアの風」に弊社代表水野真澄が出演いたしました。",
  },
  {
    date: "2013-01-04",
    media: "The Japan Times（日刊英字新聞）",
    url: "",
    description:
      "The Japan Times（日刊英字新聞）に水野のコメントが掲載されました。",
  },
  {
    date: "2012-11-25",
    media:
      "時事通信出版局発行・社団法人内外情勢調査会会員誌「J2TOP」2012年12月号",
    url: "",
    description:
      "「中国市場からの撤退を考える日本企業の課題」（文・水野真澄）が掲載されました。",
  },
  {
    date: "2012-10-27",
    media: "BSジャパン「日経スペシャル　アジアの風」",
    url: "http://www.bs-j.co.jp/asianwind/bn30.html",
    description:
      "BSジャパン「日経スペシャル　アジアの風」に弊社代表水野真澄が出演いたしました。",
  },
  {
    date: "2012-09-15",
    media: "BSジャパン「日経スペシャル　アジアの風」",
    url: "http://www.bs-j.co.jp/asianwind/bn24.html",
    description:
      "BSジャパン「日経スペシャル　アジアの風」に弊社代表水野真澄が出演いたしました。",
  },
  {
    date: "2012-09-17",
    media: "雑誌「PRESIDENTプレジデント」　2012年9月17日号（8月27日発売）",
    url: "",
    description:
      "“アジアマーケットの新ネタ10”にて、中国で売れている日本製品をピックアップしたうえ、それらの製品が何故、どの様に中国市場に受け入れられていったのか解説いたしました。",
  },
  {
    date: "2012-08-27",
    media: "雑誌「AERAアエラ」2012年8月27日号",
    url: "",
    description:
      "同号の特集“中国事業再編時代、撤退はこう進む”にて、水野真澄のコメントが掲載されました。",
  },
  {
    date: "2012-06-30",
    media: "BSジャパン「日経スペシャル　アジアの風」",
    url: "http://www.bs-j.co.jp/asianwind/bn13.html",
    description:
      "BSジャパン「日経スペシャル　アジアの風」に弊社代表水野真澄が出演いたしました。",
  },
  {
    date: "2011-05-27",
    media: "日本経済新聞 2011年5月27日朝刊（神奈川・首都圏版）第35面",
    url: "",
    description:
      "「ミズノ・コンサル、横浜に拠点」香港等で中国ビジネス支援を手掛けるミズノ・コンサルタンシー・ホールディングス（水野真澄社長）は26日、横浜市内に日本拠点を開設した。",
  },
  {
    date: "2011-05-27",
    media: "Daily NNA",
    url: "",
    description:
      "「水野コンサルティングが横浜に事務所、来月始動」中国ビジネスを専門的に手掛ける香港の水野コンサルタンシーグループ（Mizuno Consultancy Holdings Ltd水野真澄社長）は、26日、横浜市に日本代表事務所を開設した。6月1日から業務を始める。水野コンサルティングと横浜市が共同発表した。",
  },
  {
    date: "2011-05-26",
    media: "時事通信",
    url: "",
    description:
      "「香港のコンサル会社が横浜に事務所＝林市長のトップセールス第１号」横浜市は２６日、林文子市長の「海外企業横浜誘致トップセールス」の第１号として、香港を拠点に置く水野コンサルタンシー・ホールディングスが決まったと発表した。（中略）同ホールディングス（水野真澄代表）は香港を拠点に、日本企業の中国でのビジネスに関する情報提供やアドバイスを行っている。水野氏は大手商社丸紅に入社し、香港華南会社コンサルティング部長などを務めた後、独立。香港、上海、広州などで活動し、開業２年半で４００社以上の日系企業にコンサルティング業務を提供した実績を持つ、中国ビジネス展開の第一人者という。",
  },
  {
    date: "2011-05-14",
    media: "Sankei Biz",
    url: "",
    description:
      "Sankei Bizに水野真澄・著「中国人のルール」の書評が掲載される。",
  },
  {
    date: "2009-08-03",
    media: "日本工業新聞",
    url: "",
    description:
      "日本工業新聞で、水野コンサルタンシーの新会社「チェイス・チャイナ」の設立が報道される。",
  },
  {
    date: "2009-03-30",
    media: "日刊工業新聞",
    url: "",
    description:
      "3月30日付日刊工業新聞「中国ビジネスの行方　キーパーソンに聞く」のコーナーで、弊社代表取締役水野がインタビューを受け、今後の中国国内販売の展開についての対応方法を紹介しました。",
  },
  {
    date: "2008-10-23",
    media: "日本経済産業新聞",
    url: "",
    description: "日本経済産業新聞で、水野コンサルタンシーの設立が報道される。",
  },
  {
    date: "2008-09-30",
    media: "フジサンケイビジネスアイ",
    url: "",
    description:
      "フジサンケイビジネスアイで、水野代表のインタビュー（対中ビジネスの転換）が掲載される。",
  },
  {
    date: "2007-03-16",
    media: "時事通信",
    url: "",
    description: "「水野真澄の目からウロコ」連載開始",
  },
  {
    date: "2006-10-29",
    media: "毎日新聞（関西版）",
    url: "",
    description: "経済面コラム「香港だより」にて連載開始",
  },
  {
    date: "2006-08-23",
    media: "読売新聞",
    url: "",
    description: "「人物コラム」にて水野真澄が紹介される",
  },
  {
    date: "2006-06-01",
    media: "フジサンケイビジネスアイ、NNA、時事通信その他",
    url: "",
    description: "M&C上海設立が報道される。",
  },
  {
    date: "2006-05-20",
    media: "日経新聞",
    url: "",
    description: "M&C（サウスチャイナ・上海）設立が報道される。",
  },
  {
    date: "2006-01-15",
    media: "読売新聞",
    url: "",
    description:
      "DVD「ゼロからはじめる中国ビジネス、進出成功講座」が紹介される。",
  },
  {
    date: "2005-11-05",
    media: "読売新聞・衛星版",
    url: "",
    description: "水野真澄の「香港・華南トピックス」連載開始",
  },
  {
    date: "2005-09-29",
    media: "日刊工業新聞",
    url: "",
    description:
      "「中国ビジネスが本格化する中で、丸紅は中小企業向けのきめ細かい中国ビジネスコンサルティングで定評がある。香港を拠点に活躍する水野真澄丸紅華南コンサルティング部長が月額3万円という低額料金から、電話や電子メールで中小企業の各種相談に対応。変化が激しい中国最新ビジネス規定の翻訳/解説、現地法人向け会計・法務の定期巡回支援は、情報力やネットワーク力の弱い中小企業に役立っている。」",
  },
  {
    date: "2005-03-01",
    media: "フジサンケイビジネスアイ",
    url: "",
    description:
      "水野真澄がフジサンケイビジネスアイでコラムを担当（チャイナウォッチング）",
  },
  {
    date: "2005-01-22",
    media: "東洋経済",
    url: "",
    description: "水野真澄が東洋経済に中国ビジネスに関する注意点を執筆",
  },
  {
    date: "2004-09-15",
    media: "中日新聞",
    url: "",
    description: "水野真澄が香港の投資環境に関する取材を受ける。",
  },
  {
    date: "2004-07-28",
    media: "毎日新聞",
    url: "",
    description: "小売・物流に対する水野真澄のコメントが紹介される。",
  },
  {
    date: "2004-02-25",
    media: "西日本新聞",
    url: "",
    description: "水野真澄が珠江デルタに関する取材を受ける。",
  },
  {
    date: "2004-01-24",
    media: "朝日新聞",
    url: "",
    description: "水野真澄のコンサルティング展開が紹介される。",
  },
  {
    date: "2004-01-13",
    media: "チャイニーズドラゴン",
    url: "",
    description: "丸紅香港華南会社のコンサルティング業務好調との紹介を受ける。",
  },
  {
    date: "2003-09-13",
    media: "週刊ダイヤモンド",
    url: "",
    description:
      "香港特集において、水野真澄が「香港・大陸に精通したビジネスコンサルタント」として紹介される。",
  },
  {
    date: "2003-08-26",
    media: "日刊工業新聞",
    url: "",
    description:
      "わたしの1冊に、水野真澄著・中国ビジネス・投資Ｑ＆Ａが紹介される",
  },
  {
    date: "2003-06-16",
    media: "日経産業新聞",
    url: "",
    description: "丸紅が中国でコンサルティング強化との紹介を受ける",
  },
  {
    date: "2003-03-01",
    media: "日経メカニカル",
    url: "",
    description: "水野真澄が中国進出のステップに関するインタビューを受ける",
  },
  {
    date: "2003-06-01",
    media: "ＢＳ経済最前線",
    url: "",
    description: "水野真澄がＢＳ経済最前線のコメンテーターとして出演",
  },
  {
    date: "2002-11-01",
    media: "ＢＳ経済最前線",
    url: "",
    description: "水野真澄がＢＳ経済最前線のコメンテーターとして出演",
  },
];
